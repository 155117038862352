import request from "@/api/config_tests.js";

export function getAllQuestionTypes() {
  return request({
    url: `question_type/`,
    method: "get",
  });
}

export function createQuiz(data) {
  return request({
    url: `quiz/create`,
    method: "post",
    data,
  });
}

export function createQuizQuestion(data) {
  return request({
    url: `question/create`,
    method: "post",
    data,
  });
}

export function createQuizQuestionAnswer(data) {
  return request({
    url: `question_answer/create`,
    method: "post",
    data,
  });
}

export function setQuestionToQuiz(data) {
  return request({
    url: `quiz_question/create`,
    method: "post",
    data,
  });
}

export function setQuizQuestionOrder(data) {
  return request({
    url: `quiz_question_order/create`,
    method: "post",
    data,
  });
}

export function setTestAttemptAnswers(attempt_id, data) {
  return request({
    url: `quiz/set/attempt/answers/${attempt_id}/`,
    method: "post",
    data,
  });
}

export function closeTestAttempt(attempt_id, data) {
  return request({
    url: `quiz/close/attempt/${attempt_id}/`,
    method: "post",
    data,
  });
}

export function getTestAttemptAnswers(attempt_id) {
  return request({
    url: `quiz/get/attempt/answers/${attempt_id}/`,
    method: "get",
  });
}

export function getTestUserData(TestID) {
  return request({
    url: `quiz/get/test/${TestID}`,
    method: "get",
  });
}

export function getTestResult(AttemptID, TestID) {
  return request({
    url: `quiz/get/test/results/${AttemptID}/${TestID}/`,
    method: "get",
  });
}

export function findOrCreateUserAttempt(quiz_id, user_id) {
  return request({
    url: `quiz/get/attempt/findOrCreate/${quiz_id}/${user_id}`,
    method: "get",
  });
}

export function findUserAttempts(quiz_id, user_id) {
  return request({
    url: `quiz/get/attempts/findAll/${quiz_id}/${user_id}`,
    method: "get",
  });
}

export function findUserAttempt(AttemptID) {
  return request({
    url: `quiz/get/attempt/find/${AttemptID}/`,
    method: "get",
  });
}
export function getUserQuizes(userID) {
  return request({
    url: `quiz/get/tests/by/user/${userID}/`,
    method: "get",
  });
}
export function getQuizShortInfo(QuizID) {
  return request({
    url: `quiz/test/info/short/${QuizID}/`,
    method: "get",
  });
}

export function getAtemptByID(AttemptID) {
  return request({
    url: `quiz/get/attempt/result/by_id/${AttemptID}/`,
    method: "get",
  });
}

export function getQuizByID(QuizID) {
  return request({
    url: `quiz/${QuizID}/`,
    method: "get",
  });
}

export function deleteQuiz(data) {
  return request({
    url: `quiz/delete/`,
    method: "post",
    data,
  });
}

export function updateQuizData(data) {
  return request({
    url: `quiz/update/quiz/data/`,
    method: "post",
    data,
  });
}
