import axios from "axios";

// function getCookie(name) {
//   let matches = document.cookie.match(new RegExp(
//     "(?:^|; )" + name + "=([^;]*)"
//   ));
//   return matches ? decodeURIComponent(matches[1]) : undefined;
// }

const API_URL = process.env.VUE_APP_API_TEST

//api для prod с ssl
// const API_URL = "https://students.forus.ru/micro/test/api/api_tests/";

//api для dev
// const API_URL = "http://localhost:3100/api/";

// create an axios instance
const service = axios.create({
  baseURL: API_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  withCredentials: false,
  // headers: {'bearer': getCookie('_bearer_token')}
});

service.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

service.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status == 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(
          `${API_URL}auth/refresh?timestamp=${new Date().getTime()}`,
          { withCredentials: true }
        );
        localStorage.setItem("token", response.data.accessToken);
        return service.request(originalRequest);
      } catch (e) {
        // console.log('НЕ АВТОРИЗОВАН')
      }
    }
    throw error;
  }
);

export default service;
